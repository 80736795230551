<template>
    <div class="wrapper">

      <router-view></router-view>

        <!-- HeaderNavSix section -->
        <HeaderNavFour></HeaderNavFour>

        <div class="main-content">

            <!-- Breadcrumb section -->
            <Breadcrumb breadcrumbTitle="Error 404" breadcrumbName="404"></Breadcrumb>

            <!-- ErrorSection section -->
            <ErrorSection></ErrorSection>

        </div>

        <!-- Section -->
        <NewsletterThree newslatterThree="newsletter-area style-4 newsletter-space-y-axis"></NewsletterThree>

        <!-- Footer section -->
        <FooterSectionFour class="space-top-140"></FooterSectionFour>

    </div>
</template>

<script>
import HeaderNavFour from '@/components/HeaderNavFour.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ErrorSection from '@/components/ErrorSection.vue'
import NewsletterThree from '@/components/NewsletterThree.vue'
import FaqSection from '@/components/FaqSection.vue'
import FooterSectionFour from '@/components/FooterSectionFour.vue'

export default {
  components: {
    HeaderNavFour,
    Breadcrumb,
    ErrorSection,
    NewsletterThree,
    FaqSection,
    FooterSectionFour
  }
}
</script>
